import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import handleResponse from "../Helpers/handleResponse";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

let token = localStorage.getItem("dentistUserToken");
const auth = {
  // "content-type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + token,
};
const getDoctorsOffers = async (id) => {
  token = localStorage.getItem("dentistUserToken");
  const requestOptions = { method: "GET", headers: auth };
  let json = await axios.get(
    `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers?id=${2}`,
    { headers: auth }
  );
  return json.data;
};
const updateOffer = async (offer, id) => {
  console.log("updaing");
  token = localStorage.getItem("dentistUserToken");
  const requestOptions = {
    method: "POST",
    headers: auth,
    body: offer,
  };
  console.log("id", id);
  let _url = `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers`;
  if (id) {
    _url += `/${id}`;
  }

  return fetch(_url, requestOptions).then(handleResponse);
};
export async function insertOffer(offer) {
  token = localStorage.getItem("dentistUserToken");

  try {
    await axios
      .post(
        // "https://the-dentist-api.staging-apps.com/v1/doctor_dashboard/offers",
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers`,
        offer,
        {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(handleResponse);
  } catch (error) {
    console.log("error", error.response?.data?.errors);
    let errors = error.response?.data?.errors;
    let keys = Object.keys(errors);
    console.log(errors[keys[0]][0]);

    toast.error(
      errors[keys[0]][0].includes("array")
        ? "Please add at least one service"
        : errors[keys[0]][0]
    );
  }

  return;

  console.log("inserting");
  await axios
    .post(`${process.env.REACT_APP_API_URL}/doctor_dashboard/offers`, offer, {
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((e) => {
      console.log(e);
    })
    .catch((e) => {
      console.log(e);
    });
}
export async function deleteOffer(offerId, forceState) {
  let confirm = new Promise((resolve, reject) => {
    const options = {
      title: "Confirm to delete offer",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          className: "btn-danger",
          onClick: () => resolve(true),
        },
        {
          label: "No",
          onClick: () => resolve(false),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    confirmAlert(options);
  });
  console.log("confirm", confirm);
  if (await confirm) {
    console.log("DELETE");
    const requestOptions = {
      method: "DELETE",
      headers: auth,
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers/${offerId}?force=${forceState}`,
      requestOptions
    ).then(handleResponse);
  } else {
    return {};
  }
}
export async function getAll(trashedState = 1) {
  token = localStorage.getItem("dentistUserToken");
  const requestOptions = { method: "GET", headers: auth };
  return fetch(
    `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers?trashed=${trashedState}`,
    requestOptions
  ).then(handleResponse);
}
export async function getOffer(id, trashedState) {
  token = localStorage.getItem("dentistUserToken");
  const requestOptions = { method: "GET", headers: auth };
  return fetch(
    `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers/${id}?trashed=${trashedState}`,
    requestOptions
  ).then(handleResponse);
}
export const offersService = {
  getAll,
  getOffer,
  insertOffer,
  updateOffer,
  deleteOffer,
};
