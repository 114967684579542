import Spinner, { SpinnerError } from "../components/Spinner";
import { useEffect, useState } from "react";

import About from "../components/SingleDoctor/about";
import ClinicPhotos from "../components/SingleDoctor/ClinicPhotos";
import { Container } from "react-bootstrap";
import Direction from "../components/SingleDoctor/direction";
import FAQ from "../components/faq/FAQ";
import OfferCard from "../components/BookingCard/OfferCard";
import OfferInfo from "../components/SingleDoctor/doc-info/OfferInfo";
import Reviews from "../components/SingleDoctor/reviews/reviews";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BookingCard from "../components/BookingCard/BookingCard";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cart";
import OfferCard2 from "../components/BookingCard/OfferCard2";
import axios from "axios";
import { toast } from "react-toastify";

export default function SingleOffer() {
  const location = useLocation();
  console.log(location.state);
  const { status } = useSelector((state) => state.offers);
  // const { faqs } = useSelector((state) => state.faqs);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  const [doctor, setDoctor] = useState({});

  let { id } = useParams();

  const [offer, setOffer] = useState(location.state);
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    console.log("single offer : ", offer);
    let temp = {};
    temp.image = offer?.image_url;
    temp.locations = offer?.locations;
    temp.name = offer?.name.split("-")[1];
    async function fetchSingleOffer() {
      try {
        const { data } = await axios.get(`http://localhost:3004/offers/${id}`);

        setOffer(data);
      } catch (error) {
        toast.error(error.message);
      }
    }
    fetchSingleOffer();
  }, [id]);
  console.log("hello", offer);
  const [over, setOver] = useState("d-none");
  const overLay = (s) => {
    setOver(s);
  };
  const entity_gallery = [
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
  ];
  const personal_gallery = [
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
  ];
  const { cart, fees, feesWithNoDiscount, dataExistId, currentAppointment } =
    useSelector((state) => state.cart);
  const dispatch = useDispatch();
  useEffect(() => {
    offer?.services?.map((service) => {
      dispatch(
        addToCart({
          cart,
          newService: { service, count: service.pivot.quantity },
          // extraFees,
          fees: service.fees,
          // feesWithNoDiscount,
          dataId: service.id,
          // dataType,
        })
      );
    });
  }, [offer]);
  const { doctors } = useSelector((state) => state.doctors);

  useEffect(() => {
    async function fetchSingleDoctor() {
      const doctor = doctors.find((doctor) => doctor.id === parseInt(id));
      if (status === "success") {
        setDoctor(doctor);
      }
    }
    fetchSingleDoctor();
  }, [doctors, id, status]);
  if (!offer) return <h1>Loading</h1>;
  return (
    <div className=" p-top-100">
      <div className={`black-overlay ${over}`}></div>
      {true ? (
        <Container>
          <div className="doc-profile-info-cont">
            <OfferInfo data={offer} />
            {/* <OfferCard2 overLay={overLay} data={offer} /> */}
            {/* {JSON.stringify(offer)} */}
            <OfferCard
              offer={offer}
              // services={offer?.services}
              overLay={overLay}
              data={offer}
              dataName="doctor"
              confirmation={confirmation}
              handleConfirm={(value) => {
                setConfirmation(value);
                setOver(value ? "" : "d-none");
              }}
              // myAppointment={myAppointment}
            />
          </div>
          <About
            title="Description"
            borderTop="border-top"
            about={offer?.about}
          />
          <ClinicPhotos title="Clinic Photos" gallery={entity_gallery} />
          <ClinicPhotos title="Clinic Photos" gallery={personal_gallery} />
          <Direction data={offer} />
          <Reviews reviews={offer?.reviews} />

          {/* {faqs.length > 0 && <FAQ faqData={faqs} title="FAQs" />} */}
        </Container>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
