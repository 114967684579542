import { Pagination, Row } from "react-bootstrap";
import Spinner, { SpinnerError } from "../Spinner";
import { useEffect, useState } from "react";

import Offer from "./offer";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

export default function Offers({ numPerPage }) {
  const [activePage, setActivePage] = useState(1);
  const [viewedOffers, setViewedOffers] = useState([]);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  const [offerNums, setofferNums] = useState(0);

  const getOffers = async () => {
    try {
      const { data } = await axios.get(
        `http://localhost:3004/offers?_page=${activePage}&_limit=10`
      );
      setViewedOffers(data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  console.log("viewedOffers", viewedOffers);
  useEffect(() => {
    getOffers();
  }, [numPerPage, activePage]);

  const getOffersNums = async () => {
    try {
      const { data: nums } = await axios.get("http://localhost:3004/nums");

      setofferNums(nums?.offerNums);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getOffersNums();
  }, []);

  let items = [];
  const changePage = (page) => {
    setActivePage(page);
  };

  for (let number = 1; number <= Math.ceil(offerNums / 10); number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => changePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  if (!viewedOffers.length) return spinner;
  return (
    <div className="offers-cards-box">
      {viewedOffers.length > 0 ? (
        <>
          <Row>
            {viewedOffers?.map((offer) => (
              <Offer key={offer.id} offer={offer} />
            ))}
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <Pagination>{items.length > 1 ? items : null}</Pagination>
          </div>
        </>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
