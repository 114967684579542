import { useDispatch, useSelector } from "react-redux";

import AboutSection from "../components/about/AboutSection";
import BigOffersSlider from "../components/AllOffers/offersSlider";
import ContactUsPage from "../components/contact-us/ContactUsPage";
import { Container } from "react-bootstrap";
import DoctorsList from "../components/AllDoctors/DoctorsList";
import FAQ from "../components/faq/FAQ";
import FullText from "./fullText";
import LabsGrid from "../components/AllLabs/labs";
import MainSlider from "../components/home/MainSlider";
import OffersGrid from "../components/AllOffers/offers";
import Searchbar from "../components/searchbar/Searchbar";
import ServicesGrid from "../components/AllServices/services";
import SmallOffersSlider from "../components/home/TopOffers";
import Subscribe from "../components/home/Subscribe";
import TopDoctors from "../components/home/TopDoctors";
import TopLabs from "../components/home/TopScanLabs";
import TopReviews from "../components/home/TopReviews/TopReviews";
import TopServices from "../components/home/TopServices";
import { getPages } from "../redux/pages";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { langContext } from "../context/LangProvider";

export default function CustomePage({ sections }) {
  const { faqs } = useSelector((state) => state.faqs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("labs");
  console.log("sections>>>>>", sections);
  const navigateToHome = async () => {
    await dispatch(getPages("/home"));
    navigate("/");
  };
  const { t } = useContext(langContext);
  return (
    <div style={{ paddingTop: "5rem" }}>
      {sections.map((section) => {
        if (section.layout === "text-slider") {
          return (
            <MainSlider
              slides={section.content.slides}
              bgColor={section.content.bgColor}
              searchBar_visibility={section.content.searchBar_visibility}
            />
          );
        } else if (section.layout === "recommended-services") {
          return (
            <div style={{ backgroundColor: section.content.bgColor }}>
              <TopServices
                selectedServicesIds={section.content.services}
                title={t(section.title)}
                btnText={section.content.button.btnText}
                btnUrl={section.content.button.url}
                bgColor={section.content.bgColor}
              />
            </div>
          );
        } else if (section.layout === "recommended-labs") {
          return (
            <div style={{ backgroundColor: section.content.bgColor }}>
              <TopLabs
                selectedLabsIds={section.content.labs}
                title={t(section.title)}
                btnText={section.content.button.btnText}
                btnUrl={section.content.button.url}
              />
            </div>
          );
        } else if (section.layout === "recommended-doctors") {
          return (
            <TopDoctors
              selectedSpecialistsIds={section.content.doctors}
              title={t(section.title)}
              btnText={section.content.button.btnText}
              btnUrl={section.content.button.url}
              bgColor={section.content.bgColor}
            />
          );
        } else if (section.layout === "doctors") {
          return <DoctorsList numPerPage={parseInt(section.content.items)} />;
        } else if (section.layout === "text") {
          return (
            <FullText
              description={section.content.text}
              bgColor={section.content.bgColor}
            />
          );
        } else if (section.layout === "reviews") {
          return (
            <div
            // style={{ backgroundColor: section.content.bgColor }}
            >
              <TopReviews
                title={t(section.title)}
                items={section.content.items}
                // bgColor={section.content.bgColor}
              />
            </div>
          );
        } else if (section.layout === "faq") {
          return (
            <div style={{ backgroundColor: section.content.bgColor }}>
              <Container>
                {faqs.length > 0 && (
                  <FAQ
                    fullWidth="w-100"
                    faqData={
                      faqs.filter((faq) => !faq.provider_id) // to filter out any faqs that's not general
                    }
                    title={t(section.title)}
                  />
                )}
              </Container>
            </div>
          );
        } else if (section.layout === "contact-form") {
          return (
            <div style={{ backgroundColor: section.content.bgColor }}>
              <Container>
                <ContactUsPage
                  data={section.content}
                  formData={section.content.fields}
                  initialValues={section.content.initValues}
                />
              </Container>
            </div>
          );
        } else if (section.layout === "about-section") {
          console.log(section.content.imagePosition);
          return (
            <div style={{ backgroundColor: section.content.bgColor }}>
              <Container>
                <AboutSection
                  text={section.content.text}
                  image={section.content.image}
                  reversed={
                    section.content.imagePosition === "left" ? true : false
                  }
                />
              </Container>
            </div>
          );
        } else if (section.layout === "subscribe") {
          return (
            <Subscribe
              text={t(section.title)}
              bgColor={section.content.bgColor}
            />
          );
        } else if (section.layout === "search-bar") {
          return (
            <div className="py-3">
              <Searchbar title={t(section.title)} />;
            </div>
          );
        } else if (section.layout == "offers-slider") {
          if (true) {
            return (
              <div
                style={{ backgroundColor: section.content.bgColor }}
                className="pt-5 pb-2"
              >
                <Container className="mb-5">
                  <BigOffersSlider
                    title={t(section.title)}
                    slides={section.content.slides}
                  />
                  <div className="d-flex justify-content-center align-items-center m-top-30">
                    <Link to="offers">
                      <button
                        style={{ borderRadius: "7px", padding: "9px 40px" }}
                        className="scan-more pop-up"
                      >
                        SEE ALL
                        <i className="fas fa-chevron-right text-white"></i>
                      </button>
                    </Link>
                  </div>
                </Container>
              </div>
            );
          } else if (section.content.size === "17rem") {
            return (
              <div style={{ backgroundColor: section.content.bgColor }}>
                <SmallOffersSlider
                  title={t(section.title)}
                  slides={section.content.slides}
                />
              </div>
            );
          }
        } else if (section.layout === "grid") {
          if (section.content.type === "offers") {
            return (
              <div style={{ backgroundColor: section.content.bgColor }}>
                <Container>
                  <div
                    dangerouslySetInnerHTML={{ __html: t(section.title) }}
                  ></div>
                  <OffersGrid numPerPage={parseInt(section.content.items)} />
                </Container>
              </div>
            );
          } else if (section.content.type === "services") {
            return (
              <div style={{ backgroundColor: section.content.bgColor }}>
                <Container>
                  <div
                    dangerouslySetInnerHTML={{ __html: t(section.title) }}
                  ></div>
                  <ServicesGrid numPerPage={parseInt(section.content.items)} />
                </Container>
              </div>
            );
          } else if (section.content.type === "labs") {
            return (
              <div style={{ backgroundColor: section.content.bgColor }}>
                <Container>
                  <LabsGrid
                    numPerPage={parseInt(section.content.items)}
                    title={t(section.title)}
                  />
                </Container>
              </div>
            );
          }
        } else if (section.layout === "not-found") {
          return (
            <div>
              <Container className="py-5">
                <h1 className="text-center py-5 my-4">{section.content}</h1>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    onClick={navigateToHome}
                    style={{
                      borderRadius: "0.6rem",
                      textTransform: "uppercase",
                    }}
                    className="pop-up text-white p-3"
                  >
                    Back Home
                  </button>
                </div>
              </Container>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}
